import { FC } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  size: 'large' | 'small'
  color?: 'white' | 'black' | 'gray'
}

export const LoadingIndicator: FC<Props> = ({ size, color = 'white' }) => {
  const className = classNames(styles.indicator, {
    [styles.large]: size === 'large',
    [styles.small]: size === 'small',
    [styles.largeBlack]: size === 'large' && color === 'black',
    [styles.smallBlack]: size === 'small' && color === 'black',
    [styles.smallGray]: size === 'small' && color === 'gray',
  })
  return (
    <div className={styles.container}>
      <div className={className} />
    </div>
  )
}
