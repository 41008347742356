import { SettingButton } from '@/components/atoms/SettingButton'
import { MessageType } from '@/types'
import { useStores, validSolanaAddress } from '@/utils'
import { useWallet } from '@solana/wallet-adapter-react'
import { observer } from 'mobx-react'
import { FC, useState } from 'react'
import { LinkText } from '@/components/atoms/LinkText'
import styles from './index.module.scss'

type Props = {
  toggleModal: () => void
}

export const SolanaAddressFormGroup: FC<Props> = observer(({ toggleModal }) => {
  const { publicKey } = useWallet()
  const { viewer, messages } = useStores()
  const isInitialRegistration = !viewer.viewer?.profile?.solanaAddress
  const newPublicKey = publicKey?.toBase58()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onClick = async () => {
    setIsSubmitting(true)
    if (validSolanaAddress(newPublicKey)) {
      const isSuccessful = await viewer.updateMyProfile({
        solanaAddress: newPublicKey,
      })
      if (isSuccessful) {
        messages.add({
          type: MessageType.Info,
          body: 'Solana address registered',
          isTranslated: true,
          ttl: 5000,
        })
      }
    }
    setIsSubmitting(false)
    toggleModal()
  }

  return (
    <>
      <p className={styles.text}>
        {isInitialRegistration ? (
          <>
            Do you want to register
            <br />
            <span className={styles.textBold}>{newPublicKey}</span>
            <br />
            as your Solana address in your AQA account?
            <br />
            <br />
            By registering, you will receive aqa tokens for using AQA services.
            <br />
            You can change your Solana address at any time from{' '}
            <LinkText href='/account/profile'>your profile setting page.</LinkText>
          </>
        ) : (
          <>
            Now,
            <br />
            <span className={styles.textBold}>{viewer.viewer?.profile?.solanaAddress}</span>
            <br />
            is registered in your AQA account as your Solana address.
            <br />
            <br />
            Would you like to change
            <br />
            <span className={styles.textBold}>{newPublicKey}</span>
            <br />
            as your Solana address?
            <br />
            <br />
            You can change your Solana address at any time from{' '}
            <LinkText href='/account/profile'>your profile setting page.</LinkText>
          </>
        )}
      </p>
      <div className={styles.btnContainer}>
        <SettingButton
          disabled={!newPublicKey}
          buttonType='button'
          buttonSize='medium'
          color='blackOutline'
          onClick={toggleModal}
        >
          Cancel
        </SettingButton>
        <SettingButton
          isLoading={isSubmitting}
          disabled={!newPublicKey}
          buttonSize='medium'
          color='aqa'
          onClick={onClick}
        >
          {isInitialRegistration ? 'Register' : 'Change'}
        </SettingButton>
      </div>
    </>
  )
})
