import { FC, MouseEventHandler } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  isOpen: boolean
  onClick: MouseEventHandler<HTMLDivElement>
  size?: 'small' | 'normal'
  color?: 'white' | 'gray'
}

export const HamburgerIcon: FC<Props> = ({ isOpen, onClick, size = 'normal', color = 'white' }) => {
  const className = classNames(
    styles.container,
    {
      [styles.small]: size === 'small',
    },
    {
      [styles.gray]: color === 'gray',
    },
    {
      [styles.menuOpen]: isOpen,
    }
  )

  return (
    <div className={className} onClick={onClick} onKeyDown={() => {}} role='button' tabIndex={0}>
      <div className={styles.lines}>
        <span className={styles.line} />
        <span className={styles.line} />
        <span className={styles.line} />
      </div>
    </div>
  )
}
