import { CloseButton } from '@/components/atoms/CloseButton'
import { OutSideOverlay } from '@/components/atoms/OutSideOverlay'
import { useStores } from '@/utils'
import { FC } from 'react'
import { SolanaAddressFormGroup } from '../SolanaAddressFormGroup'
import styles from './index.module.scss'

export const SolanaAddressModal: FC = () => {
  const { ui, viewer } = useStores()

  const toggleModal = () => {
    if (!viewer.isInitialized) {
      return
    }

    ui.toggleSolanaAddressModal()
  }

  return (
    <div style={ui.isSolanaAddressModalOpen ? {} : { display: 'none' }}>
      <OutSideOverlay onClick={toggleModal} isOpen={ui.isSolanaAddressModalOpen} backGroundColor='gray' />
      <div className={styles.modal}>
        <div className={styles.card}>
          <div className={styles.heading}>
            <h2>Solana Address</h2>
            <div className={styles.closeButton}>
              <CloseButton onClick={toggleModal} isLarge />
            </div>
          </div>
          <SolanaAddressFormGroup toggleModal={toggleModal} />
        </div>
      </div>
    </div>
  )
}
