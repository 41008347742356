import { FC } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

export type AvatarStyleType =
  | 'circle'
  | 'roundedRectangle'
  | 'square'
  | 'roundedSquare'
  | 'roundedLargeSquare'
  | 'roundedFullWidthSquare'

type Props = {
  src: string
  alt?: string
  styleType?: AvatarStyleType
  isOutline?: boolean
}

export const Avatar: FC<Props> = ({ src, alt = '', styleType = 'circle', isOutline = false }) => {
  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.circle]: styleType === 'circle',
          [styles.roundedRectangle]: styleType === 'roundedRectangle',
          [styles.square]: styleType === 'square',
          [styles.roundedSquare]: styleType === 'roundedSquare',
          [styles.roundedFullWidthSquare]: styleType === 'roundedFullWidthSquare',
        },
        {
          [styles.outline]: isOutline,
        }
      )}
    >
      <img src={src} alt={alt} />
    </div>
  )
}
