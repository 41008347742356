import { FC, useEffect } from 'react'
import { useStores } from '@/utils'
import { useWallet } from '@solana/wallet-adapter-react'
import { observer } from 'mobx-react'
import { SolanaAddressModal } from '../SolanaAddressModal'

export const SolanaAddressModalGroup: FC = observer(() => {
  const { publicKey } = useWallet()
  const { ui, viewer } = useStores()

  const toggleModal = () => {
    if (!ui.isSolanaAddressModalOpen) {
      ui.toggleSolanaAddressModal()
    }
  }

  useEffect(() => {
    if (viewer.isInitialized && viewer.isSignedIn && publicKey) {
      // solanaAddress が登録されていないか、wallet のアドレスと違う場合はモーダルを表示する
      if (!viewer.viewer?.profile?.solanaAddress || viewer.viewer?.profile?.solanaAddress !== publicKey.toBase58()) {
        toggleModal()
      }
    }
  }, [publicKey, viewer.isInitialized, viewer.isSignedIn])

  if (ui.isSolanaAddressModalOpen) {
    return (
      <>
        <SolanaAddressModal />
      </>
    )
  }

  return <></>
})
