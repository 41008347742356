import { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { FormikHelpers } from 'formik'
import { MessageType } from '@/types'
import { useStores } from '@/utils'
import { InvitationModal, InvitationValue } from '@components/molecules/InvitationModal'

export const UserInvitationModal: FC = observer(() => {
  const { viewer, messages, ui } = useStores()
  const [isLoading, setIsLoading] = useState(false)
  const [invitationLink, setInvitationLink] = useState('Publish the invitation link')

  const copyLink = async () => {
    setIsLoading(true)
    const url = await viewer.viewer?.addUserInvitation({})
    setInvitationLink(url)
    setIsLoading(false)

    if (url) {
      const isSuccessful = await navigator.clipboard.writeText(url).then(
        () => {
          return true
        },
        () => {
          return false
        }
      )
      const messageText = isSuccessful ? 'Link copied!' : 'Failed to copy link'

      messages.add({
        type: MessageType.Info,
        body: messageText,
        ttl: 5000,
      })
    }
  }

  const toggleModal = () => {
    ui.toggleUserInvitationModal()
  }

  const onSubmit = async (values: InvitationValue, actions: FormikHelpers<InvitationValue>) => {
    const url = await viewer.viewer?.addUserInvitation({
      emails: values.emails,
    })
    setInvitationLink(url)
    actions.setSubmitting(false)

    if (url) {
      messages.add({
        type: MessageType.Info,
        body: 'Link has been sent',
        ttl: 5000,
      })
      actions.resetForm()
    }
  }

  return (
    <InvitationModal
      title='Spread the word! Refer a friend.'
      subTitle='Invite your friends to join AQA and receive AQA tokens!'
      notice='*The aqa token will only be sent if the invited user has registered profile image and connected wallet'
      isLoading={isLoading}
      copyLink={copyLink}
      onSubmit={(values: InvitationValue, actions: FormikHelpers<InvitationValue>) => onSubmit(values, actions)}
      toggleModal={toggleModal}
      isUserInvitation
      invitationLink={invitationLink}
    />
  )
})
