import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  label?: string
  hasForm?: boolean
  isWide?: boolean
  isRequired?: boolean
  isColumn?: boolean
  hasLabelOnTop?: boolean
  isNormalFontSize?: boolean
  hasLabelPaddingRight?: boolean
  hasNotLabelMarginBottom?: boolean
  color?: 'gray' | 'black'
}

export const FormItem: FC<PropsWithChildren<Props>> = ({
  children,
  label = '',
  hasForm = false,
  isWide = false,
  isRequired = false,
  isColumn = false,
  hasLabelOnTop = false,
  isNormalFontSize = false,
  hasLabelPaddingRight = false,
  hasNotLabelMarginBottom = false,
  color = 'gray',
}) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.columnContainer]: isColumn,
        [styles.labelOnTopContainer]: hasLabelOnTop,
      })}
    >
      <div
        className={classNames({
          [styles.label]: !isColumn,
          [styles.columnLabel]: isColumn,
          [styles.required]: isRequired,
          [styles.isNormal]: isNormalFontSize,
          [styles.hasLabelPaddingRight]: hasLabelPaddingRight,
          [styles.hasNotLabelMarginBottom]: hasNotLabelMarginBottom,
          [styles.gray]: color === 'gray',
          [styles.black]: color === 'black',
        })}
      >
        {label}
      </div>
      <div
        className={classNames({
          [styles.content]: !isColumn,
          [styles.columnContent]: isColumn,
          [styles.hasForm]: hasForm,
          [styles.isWide]: isWide,
        })}
      >
        {children}
      </div>
    </div>
  )
}
