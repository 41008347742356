import { FC } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import NotificationsSvg from '@/public/images/common/notifications.svg'
import NotificationsActiveSvg from '@/public/images/common/notifications_active.svg'
import { useStores } from '@/utils'
import styles from './index.module.scss'

type Props = {
  isMobile?: boolean
  isActive?: boolean
}

export const NotificationBell: FC<Props> = observer(({ isMobile, isActive }) => {
  const { ui, viewer } = useStores()

  const onClick = (): void => {
    ui.toggleNotificationMenu()
  }

  const iconClassName = classNames(styles.icon, {
    [styles.withMark]: viewer.viewer.hasUnreadNotifications,
    [styles.activeIcon]: isActive,
  })

  const textClassName = classNames(styles.text, {
    [styles.textForMobile]: isMobile,
    [styles.activeText]: isActive,
  })

  return (
    <button className={isMobile ? styles.containerForMobile : styles.container} type='button' onClick={onClick}>
      <div className={iconClassName}>{isActive ? <NotificationsActiveSvg /> : <NotificationsSvg />}</div>
      <p className={textClassName}>Notifications</p>
    </button>
  )
})
