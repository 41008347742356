import { FC } from 'react'
import { observer } from 'mobx-react'
import { NotificationBell } from '@components/atoms/NotificationBell'
import { NotificationMenu } from '@components/organisms/NotificationMenu'
import { useStores } from '@/utils'
import styles from './index.module.scss'

type Props = {
  isMobile?: boolean
  isActive?: boolean
}

export const GlobalNotificationGroup: FC<Props> = observer(({ isMobile, isActive }) => {
  const { ui } = useStores()

  const onClick = (): void => {
    ui.toggleNotificationMenu()
  }

  return (
    <>
      {ui.isNotificationMenuOpen && (
        <div className={styles.outSide} role='button' tabIndex={0} onClick={onClick} onKeyPress={() => {}}>
          outside
        </div>
      )}
      <div className={styles.container}>
        <NotificationBell isMobile={isMobile} isActive={isActive} />
        {ui.isNotificationMenuOpen && (
          <div className={styles.menuContainer}>
            <div className={styles.menu}>
              <NotificationMenu />
            </div>
          </div>
        )}
      </div>
    </>
  )
})
