import { FC } from 'react'
import { SelectedTag } from '../../atoms/SelectedTag'
import { EmailsInputField } from '../EmailsInputField'
import styles from './index.module.scss'

type Props = {
  selectedEmails: string[]
  setFieldValue: (emails: string[]) => void
}

export const EmailsField: FC<Props> = ({ selectedEmails, setFieldValue }) => {
  return (
    <>
      <EmailsInputField selectedEmails={selectedEmails} setFieldValue={(emails) => setFieldValue(emails)} />
      <div className={styles.tags}>
        {selectedEmails?.map((email) => {
          return (
            <div key={email} className={styles.item}>
              <SelectedTag
                name={email}
                onDeleteClick={() =>
                  setFieldValue(
                    selectedEmails?.filter((e) => e !== email) // 削除する email 以外の配列を値にセットする
                  )
                }
              />
            </div>
          )
        })}
      </div>
    </>
  )
}
