import { FC, MouseEventHandler } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'
import { LoadingIndicator } from '../LoadingIndicator'

type Props = {
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  isLoading?: boolean
  invitationLink?: string
}

export const CopyLinkField: FC<Props> = ({ onClick, disabled = false, isLoading = false, invitationLink }) => {
  const button = classNames(styles.button, {
    [styles.loading]: isLoading,
  })

  return (
    <div className={styles.container}>
      <div className={styles.text}>{invitationLink}</div>
      <button type='button' className={button} onClick={onClick} disabled={disabled}>
        {isLoading ? (
          <div className={styles.loadingContainer}>
            <LoadingIndicator size='small' color='black' />
          </div>
        ) : (
          <div className={styles.innerContainer}>Publish link</div>
        )}
      </button>
    </div>
  )
}
