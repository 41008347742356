import Link from 'next/link'
import { FC, PropsWithChildren } from 'react'
import styles from './index.module.scss'

type Props = {
  href: string
  target?: string
}

export const LinkText: FC<PropsWithChildren<Props>> = ({ href, target = null, children }) => {
  if (target) {
    return (
      <a href={href} target={target} className={styles.link}>
        {children}
      </a>
    )
  }

  return (
    <Link href={href || ''}>
      <a className={styles.link}>{children}</a>
    </Link>
  )
}
