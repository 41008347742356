import { WalletButton, WalletButtonProps } from '@/components/atoms/WalletButton'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { WalletConnectButton, WalletModalButton } from '@solana/wallet-adapter-react-ui'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SolanaAddressModalGroup } from '@/components/organisms/SolanaAddressModalGroup'
import { useStores } from '@/utils'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import WalletSvg from '@/public/images/common/wallet.svg'
import WalletActiveSvg from '@/public/images/common/wallet_active.svg'
import styles from './index.module.scss'

// @solana/wallet-adapter-react-ui の WalletMultiButton を参考に実装
// 最初 phantom wallet しか対応しないので change wallet の機能は未実装
export const WalletButtonGroup: FC<WalletButtonProps> = observer(
  ({ className = styles.container, disabled, endIcon, onClick, startIcon, style, tabIndex }) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { publicKey, wallet, disconnect } = useWallet()
    const { viewer } = useStores()
    const { connection } = useConnection()
    const [copied, setCopied] = useState(false)
    const [active, setActive] = useState(false)
    const ref = useRef<HTMLUListElement>(null)

    const base58 = useMemo(() => publicKey?.toBase58(), [publicKey])

    const copyAddress = useCallback(async () => {
      if (base58) {
        await navigator.clipboard.writeText(base58)
        setCopied(true)
        setTimeout(() => setCopied(false), 400)
      }
    }, [base58])

    const openDropdown = async () => {
      setActive(true)

      await getBalance()
    }

    const closeDropdown = useCallback(() => {
      setActive(false)
    }, [])

    useEffect(() => {
      const listener = (event: MouseEvent | TouchEvent) => {
        const node = ref.current

        // Do nothing if clicking dropdown or its descendants
        if (!node || node.contains(event.target as Node)) {
          return
        }

        closeDropdown()
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }, [ref, closeDropdown])

    const getBalance = async () => {
      if (!connection || !publicKey) {
        return
      }

      try {
        await viewer.viewer?.getSolBalance(publicKey, connection)
        await viewer.viewer?.findATAOrUpdateBalance(publicKey, connection)
      } catch {
        viewer.viewer?.updateSolBalance('')
      }
    }

    useEffect(() => {
      getBalance()
    }, [connection, publicKey])

    if (!wallet) {
      return (
        <WalletModalButton
          className={className}
          disabled={disabled}
          endIcon={endIcon}
          onClick={onClick}
          startIcon={startIcon}
          style={style}
          tabIndex={tabIndex}
        >
          <div className={styles.item}>
            <div className={styles.walletIcon}>
              <WalletSvg />
            </div>
            <p className={styles.text}>Wallet</p>
          </div>
        </WalletModalButton>
      )
    }
    if (!base58) {
      return (
        <WalletConnectButton
          className={className}
          disabled={disabled}
          endIcon={endIcon}
          onClick={onClick}
          startIcon={startIcon}
          style={style}
          tabIndex={tabIndex}
        >
          <div className={styles.item}>
            <div className={styles.walletIcon}>
              <WalletSvg />
            </div>
            <p className={styles.text}>Wallet</p>
          </div>
        </WalletConnectButton>
      )
    }

    return (
      <div className={styles.dropdown}>
        <WalletButton
          aria-expanded={active}
          className={className}
          style={{ pointerEvents: active ? 'none' : 'auto', ...style }}
          onClick={openDropdown}
          startIcon={startIcon}
          disabled={disabled}
          endIcon={endIcon}
          tabIndex={tabIndex}
        >
          <div className={styles.item}>
            <div className={styles.activeWalletIcon}>
              <WalletActiveSvg />
            </div>
            <p className={styles.text}>Wallet</p>
          </div>
        </WalletButton>
        <ul
          aria-label='dropdown-list'
          className={classNames(styles.dropdownList, { [styles.active]: active })}
          ref={ref}
          role='menu'
        >
          {(viewer.viewer?.solBalance || viewer.viewer?.tokenBalances[publicKey.toBase58()]) && (
            <div className={styles.dropdownBalances}>
              {viewer.viewer?.solBalance && (
                <li className={classNames(styles.listItem, styles.isBalance)} role='menuitem'>
                  <div className={styles.iconContainer}>
                    <div className={styles.icon}>
                      <img src='/images/common/sol_icon.png' alt='SOL' />
                    </div>
                    <span className={styles.symbol}>SOL</span>
                  </div>
                  <span className={styles.balance}>{viewer.viewer?.solBalance}</span>
                </li>
              )}
              {viewer.viewer?.tokenBalances[publicKey.toBase58()] && (
                <li className={classNames(styles.listItem, styles.isBalance)} role='menuitem'>
                  <div className={styles.iconContainer}>
                    <div className={styles.icon}>
                      <img src={viewer.viewer?.tokenInfo.image} alt='AQA' />
                    </div>
                    <span className={styles.symbol}>AQA</span>
                  </div>
                  <span className={styles.balance}>{viewer.viewer?.tokenBalances[publicKey.toBase58()]}</span>
                </li>
              )}
            </div>
          )}
          <div className={styles.walletContent}>
            <li
              onClick={copyAddress}
              onKeyDown={copyAddress}
              className={classNames(styles.listItem, styles.isBtn)}
              role='menuitem'
            >
              {copied ? 'Copied' : 'Copy address'}
            </li>
            <li
              onClick={disconnect}
              onKeyDown={disconnect}
              className={classNames(styles.listItem, styles.isBtn)}
              role='menuitem'
            >
              Disconnect
            </li>
          </div>
        </ul>
        <SolanaAddressModalGroup />
      </div>
    )
  }
)
