import { FC, useEffect } from 'react'
import { observer } from 'mobx-react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import styles from './index.module.scss'
import { Avatar } from '../../atoms/Avatar'
import { getCompanyLogo, getTimeAgoAndTranslationKey, getUserAvatar, useStores } from '../../../utils'
import { NoContent } from '../../atoms/NoContent'
import { NotificationCategory } from '../../../types'

export const NotificationMenu: FC = observer(() => {
  const { viewer, ui } = useStores()

  const { t } = useTranslation(['global'])

  useEffect(() => {
    viewer.viewer.markAllNotificationsAsRead()
  }, [viewer.isInitialized])

  const onClick = () => {
    ui.toggleNotificationMenu()
  }

  if (viewer.viewer.latestNotifications.length === 0) {
    return (
      <div className={styles.noNotificationContainer}>
        <div className={styles.noContent}>
          <NoContent>{t('通知がありません')}</NoContent>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {viewer.viewer.latestNotifications.map((notification) => {
        const { timeAgo, key } = getTimeAgoAndTranslationKey(notification?.createdAt)
        let href = ''
        let logo = ''
        let message = ''

        if (notification?.category === NotificationCategory.RECRUITING) {
          // 求人メッセージ
          href = `/companies/${notification?.newRecruitingMessageNotification.company.slug}/account/recruit/messages/${notification?.newRecruitingMessageNotification.message.messageThread.slug}`
          const sender = notification?.newRecruitingMessageNotification?.message.user
          logo = getUserAvatar(sender)
          message = t('さんからメッセージが届いています。', { name: sender?.name })
        } else if (notification?.category === NotificationCategory.MESSAGE) {
          // メッセージ
          href = `/messages/${notification?.newMessageNotification.message.messageThread.slug}`
          const sender = notification?.newMessageNotification?.message.user
          logo = getUserAvatar(sender)
          message = t('さんからメッセージが届いています。', { name: sender?.name })
        } else if (notification?.category === NotificationCategory.USER_FOLLOWED) {
          // ユーザーにフォローされた
          const sender = notification?.newUserFollowerNotification.user
          href = `/users/${sender?.username}`
          logo = getUserAvatar(sender)
          message = t('さんにフォローされました。', { name: sender?.name })
        } else if (notification?.category === NotificationCategory.NEW_JOB_OPENING) {
          // 求人開始
          const sender = notification?.newJobOpeningNotification.company
          href = `/companies/${sender?.slug}`
          logo = getCompanyLogo(sender)
          message = t('が求人を公開しました。', { name: sender?.name })
        } else if (notification?.category === NotificationCategory.JOB_SEEKING_STATUS_UPDATED) {
          // 求職活動開始
          const sender = notification?.jobSeekingStatusUpdatedNotification.user
          href = `/users/${sender?.username}`
          logo = getUserAvatar(sender)
          message = t('さんが求職活動を開始しました。', { name: sender?.name })
        } else if (notification?.category === NotificationCategory.ANSWER) {
          // リプライされた
          const sender = notification?.answerNotification.answer.respondent
          href = `/forum/${notification?.answerNotification.answer.rootQuestion.slug}`
          logo = getUserAvatar(sender)
          message = `${sender?.name} replied to your post.`
        } else if (notification?.category === NotificationCategory.TOKEN_TRANSACTION) {
          // 投げ銭された
          const sender = notification?.tokenTransactionNotification.tokenTransaction.sender
          href = `/users/${sender?.username}`
          logo = getUserAvatar(sender)
          message = `${sender?.name} has sent you ${notification?.tokenTransactionNotification.tokenTransaction.amount} AQA.`
        }

        return (
          <Link key={notification.id} href={href}>
            <div className={styles.item} onClick={onClick} onKeyPress={onClick} role='button' tabIndex={0}>
              <div className={styles.avatar}>
                <Avatar src={logo} />
              </div>
              <div className={styles.right}>
                <p className={styles.message}>{message}</p>
                <p className={styles.time}>{t(key, { count: timeAgo })}</p>
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
})
