import { FC } from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { IMessage, MessageState, MessageType } from '../../../types'
import styles from './index.module.scss'

type Props = {
  message: IMessage
}

export const Toast: FC<Props> = observer(({ message }) => {
  const { t } = useTranslation('global')
  return (
    <div
      className={cn(styles.container, {
        [styles.visibleContainer]: message.state === MessageState.Visible,
        [styles.infoContainer]: message.type === MessageType.Info,
        [styles.errorContainer]: message.type === MessageType.Error,
        [styles.containerWithCloseButton]: message.isDismissable,
      })}
    >
      <p
        className={cn(styles.body, {
          [styles.bodyWithCloseButton]: message.isDismissable,
        })}
      >
        {message.isTranslated ? message.body : t(message.body)}
      </p>
      {message.isDismissable && (
        <button
          type='button'
          className={styles.closeButton}
          onClick={() => {
            message.hide()
          }}
        >
          ×
        </button>
      )}
    </div>
  )
})
