import { FC, FocusEvent, KeyboardEvent, useState } from 'react'
import { observer } from 'mobx-react'
import styles from './index.module.scss'

type Props = {
  selectedEmails: string[]
  setFieldValue: (emails: string[]) => void
}

export const EmailsInputField: FC<Props> = observer(({ selectedEmails, setFieldValue }) => {
  // 日本語変換判定用
  const [composing, setComposing] = useState(false)
  const startComposition = () => setComposing(true)
  const endComposition = () => setComposing(false)

  const onBlurText = (e: FocusEvent<HTMLInputElement>) => {
    if (composing) {
      return
    }

    const { value } = e.currentTarget
    if (value) {
      // ユニークなメールアドレスでない場合追加しない
      const isSelected = selectedEmails?.some((email) => email === value)
      if (!isSelected) {
        const newEmails = selectedEmails.concat(value)
        setFieldValue(newEmails)
        // フィールドの値を削除
        e.currentTarget.value = ''
      }
      e.preventDefault()
    }
  }

  const onKeyDownText = (e: KeyboardEvent<HTMLInputElement>) => {
    if (composing) {
      return
    }

    const { value } = e.currentTarget
    if (value && e.key === 'Enter') {
      // ユニークなメールアドレスでない場合追加しない
      const isSelected = selectedEmails?.some((email) => email === value)
      if (!isSelected) {
        const newEmails = selectedEmails.concat(value)
        setFieldValue(newEmails)
        // フィールドの値を削除
        e.currentTarget.value = ''
      }
      e.preventDefault()
    }
  }

  return (
    <input
      type='text'
      className={styles.input}
      placeholder='Enter an email to invite'
      onBlur={onBlurText}
      onKeyDown={onKeyDownText}
      onCompositionStart={startComposition}
      onCompositionEnd={endComposition}
    />
  )
})
