import { FC } from 'react'
import { observer } from 'mobx-react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useStores } from '@/components/../utils'
import { OutSideOverlay } from '@/components/atoms/OutSideOverlay'
import { FormItem } from '@/components/atoms/FormItem'
import { SettingButton } from '@/components/atoms/SettingButton'
import { EmailsField } from '@/components/molecules/EmailsField'
import { ValidationErrorMessage } from '@/components/atoms/ValidationErrorMessage'
import { CopyLinkField } from '@/components/atoms/CopyLinkField'
import styles from './index.module.scss'

type Props = {
  title: string
  subTitle?: string
  notice?: string
  isLoading: boolean
  copyLink: () => void
  onSubmit: (values: InvitationValue, actions: FormikHelpers<InvitationValue>) => void
  toggleModal: () => void
  isUserInvitation?: boolean
  invitationLink?: string
}

export type InvitationValue = {
  emails: string[]
}

export const InvitationModal: FC<Props> = observer(
  ({ title, subTitle, notice, isLoading, copyLink, onSubmit, toggleModal, isUserInvitation, invitationLink }) => {
    const { ui } = useStores()

    const initialValue: InvitationValue = {
      emails: [],
    }

    const validationSchema = Yup.object().shape({
      emails: Yup.array()
        .of(Yup.string().email('Email address format is different.'))
        .min(1, 'This field is required.'),
    })

    const isOpen = isUserInvitation ? ui.isUserInvitationModalOpen : ui.isInvitationModalOpen

    return (
      <div className={styles.container} style={isOpen ? {} : { display: 'none' }}>
        <OutSideOverlay onClick={toggleModal} isOpen={isOpen} backGroundColor='gray' />
        <div className={styles.modal}>
          <div className={styles.heading}>
            <div className={styles.headingContent}>
              <h2>{title}</h2>
              <p>{subTitle}</p>
              {notice && <p>{notice}</p>}
            </div>
            <div className={styles.logo}>
              <img src='/images/common/tokens_icon.png' alt='tokens' />
            </div>
          </div>
          <div className={styles.body}>
            <>
              <Formik initialValues={initialValue} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ isSubmitting, setFieldValue, values, touched, errors }) => {
                  return (
                    <Form>
                      <div className={styles.link}>
                        <FormItem label='Personal Link' isColumn color='black'>
                          <CopyLinkField
                            disabled={isLoading}
                            isLoading={isLoading}
                            onClick={copyLink}
                            invitationLink={invitationLink}
                          />
                        </FormItem>
                      </div>
                      <div>
                        <FormItem label='Send as email invite' isColumn color='black'>
                          <div className={styles.inviteBtn}>
                            <div className={styles.emailsField}>
                              <EmailsField
                                selectedEmails={values.emails}
                                setFieldValue={(emails) => setFieldValue('emails', emails)}
                              />
                            </div>
                            <SettingButton
                              isLoading={isSubmitting}
                              disabled={isSubmitting}
                              buttonType='submit'
                              color='black'
                              buttonSize='medium'
                            >
                              Invite
                            </SettingButton>
                          </div>
                          {touched.emails && errors.emails && (
                            <div>
                              {/* メールアドレスの形式が違う値が複数ある場合メッセージも複数表示されるので、メールアドレス形式のエラーは一つだけ表示さうるよう調整 */}
                              <ValidationErrorMessage>
                                {Array.isArray(errors.emails) ? errors.emails.find((e) => e) : errors.emails}
                              </ValidationErrorMessage>
                            </div>
                          )}
                        </FormItem>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
              {/* 有効な invitationLink なら表示 */}
              {/^(https?|ftp):\/\//.test(invitationLink) && (
                <a
                  href={`http://twitter.com/share?url=${invitationLink}`}
                  target='_blank'
                  rel='nofollow noopener noreferrer'
                  className={styles.share}
                >
                  <img src='/images/common/twitter_icon.png' alt='Twitter' />
                </a>
              )}
            </>
          </div>
        </div>
      </div>
    )
  }
)
