import { FC, MouseEventHandler, PropsWithChildren } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import styles from './index.module.scss'
import { LoadingIndicator } from '../LoadingIndicator'
import { sanitizeUrl } from '../../../utils'

type Props = {
  href?: string
  target?: string
  componentType?: 'button' | 'a'
  color?: 'blue' | 'white' | 'red' | 'black' | 'blueOutline' | 'grayOutline' | 'blackOutline' | 'aqa'
  buttonType?: 'button' | 'submit' | 'reset'
  buttonSize?: 'small' | 'medium'
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  isLoading?: boolean
  loadingColor?: 'white' | 'black'
}

export const SettingButton: FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
  href = '',
  target = null,
  color = 'blue',
  componentType = 'button',
  buttonType = 'button',
  disabled = false,
  isLoading = false,
  loadingColor = 'white',
  buttonSize = 'size',
}) => {
  const className = classNames(
    styles.container,
    {
      [styles.blue]: color === 'blue',
      [styles.white]: color === 'white',
      [styles.red]: color === 'red',
      [styles.black]: color === 'black',
      [styles.blueOutline]: color === 'blueOutline',
      [styles.grayOutline]: color === 'grayOutline',
      [styles.blackOutline]: color === 'blackOutline',
      [styles.aqa]: color === 'aqa',
    },
    {
      [styles.loading]: isLoading,
    },
    {
      [styles.mediumButton]: buttonSize === 'medium',
    }
  )

  if (componentType === 'a') {
    if (target) {
      return (
        <a href={href} className={className} target={target}>
          {children}
        </a>
      )
    }

    return (
      <Link href={sanitizeUrl(href)}>
        <a className={className}>{children}</a>
      </Link>
    )
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={buttonType} className={className} onClick={onClick} disabled={disabled}>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingIndicator size='small' color={loadingColor} />
        </div>
      ) : (
        children
      )}
    </button>
  )
}
