import { FC } from 'react'
import styles from './index.module.scss'

type Props = {
  name: string
  nameTestid?: string
  buttonTestid?: string
  onDeleteClick: () => void
}

export const SelectedTag: FC<Props> = ({ name, nameTestid, buttonTestid, onDeleteClick }) => {
  return (
    <div className={styles.container}>
      <div className={styles.name} data-testid={nameTestid}>
        {name}
      </div>
      <button type='button' onClick={onDeleteClick} className={styles.deleteButton} data-testid={buttonTestid}>
        <img src='/images/common/delete_gray_icon.svg' alt='Delete' />️
      </button>
    </div>
  )
}
